import './App.css';

// import React, { useState } from 'react';

// import Login from './component/auth/Login';
// import Signup from './component/auth/Signup';
import AuthSession from './component/auth/AuthSession';
import TestForm from './component/auth/TestForm';

function App() {

  // const [showLogin, setShowLogin] = useState(true);

  // const toggleForm = () => {
  //   setShowLogin(!showLogin);
  // };

  return (
    <div className="App" style={{ width: '300px', margin: '100px auto' }}>
      {/*{showLogin ? (
        <>
          <Login />
          <sl-button size="small" onClick={toggleForm}>Switch to Sign Up</sl-button>
        </>
      ) : (
        <>
          <Signup />
          <sl-button size="small" onClick={toggleForm}>Switch to Login</sl-button>
        </>
      )}*/}
      <TestForm />
      <AuthSession />
    </div>
  );
}

export default App;
