import React, { useEffect, /*useState*/ } from 'react';

function SimpleTestForm() {
  useEffect(() => {
  	if (document.querySelector('script[src="https://challenges.cloudflare.com/turnstile/v0/api.js"]')) {
    console.log("Turnstile script already loaded.");
    return;
  }
    const script = document.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <form>
      <div className="cf-turnstile" data-sitekey="0x4AAAAAAAe1sQWUjYMTI8hD"></div>
      <button type="submit">Submit</button>
    </form>
  );
}

export default SimpleTestForm;
