import React, { useEffect, useState } from 'react';
import { supabase } from '../../supabaseClient';

function AuthSession() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    // Define an async function to fetch session data
    async function fetchSession() {
      const { data: currentSession, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Error fetching session:', error.message);
        return;
      }
      setSession(currentSession);
      console.log('hi - currentSession is:', currentSession);
    }

    // Call the async function
    fetchSession();

    // Setup a subscription for auth state changes
    const { data } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      console.log('We set the session:', session);
    });


    // const { data } = supabase.auth.onAuthStateChange((event, session) => {
    //   console.log(event, session)

    //   if (event === 'INITIAL_SESSION') {
    //     // handle initial session
    //   } else if (event === 'SIGNED_IN') {
    //     // handle sign in event
    //   } else if (event === 'SIGNED_OUT') {
    //     // handle sign out event
    //   } else if (event === 'PASSWORD_RECOVERY') {
    //     // handle password recovery event
    //   } else if (event === 'TOKEN_REFRESHED') {
    //     // handle token refreshed event
    //   } else if (event === 'USER_UPDATED') {
    //     // handle user updated event
    //   }
    // })

    // data.subscription.unsubscribe()


    // Cleanup the listener
    return () => {
      // call unsubscribe to remove the callback
      data.subscription.unsubscribe();
    };
  }, []);

  return (
    <div>
      {session && session.user ? <p>Logged in as {session.user.email}</p> : <p>Not logged in</p>}
    </div>
  );
}

export default AuthSession;
